export default defineNuxtPlugin(() => {
  const $device = useDevice()
  const customDevice: CustomDevice = { ...$device, isIpad: false }
  if (process.client) {
    customDevice.isIpad =
      /iPad/i.test($device.userAgent) /* iOS pre 13 */ ||
      (/Macintosh/i.test($device.userAgent) && navigator.maxTouchPoints > 1)

    customDevice.isMobileOrTablet =
      customDevice.isMobileOrTablet || customDevice.isIpad
  }
  return {
    provide: {
      customDevice
    }
  }
})
