<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#4b52ff" />
    <NuxtPage class="nuxt_page" />
  </NuxtLayout>
</template>
<script setup lang="ts">
const { isOpenMobileMenu } = storeToRefs(useGlobalStore())
const metaTitle = '헬로메이플'
const metaDesc = '미래를 만드는 블록 코딩 교실'

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk && titleChunk !== metaTitle
      ? `${titleChunk} - ${metaTitle}`
      : metaTitle
  }
})
useCustomMeta(metaTitle, metaDesc)

watch(
  () => isOpenMobileMenu.value,
  (n) => {
    document.body.classList[n ? 'add' : 'remove']('hidden_scroll')
  }
)

if (process.client) {
  // 보안 검수 대응
  const scripts = document.querySelectorAll('body script')
  const length = scripts.length
  if (length > 0) {
    for (let idx = 0; idx < length; idx++) {
      const target = scripts[idx]
      if (/^window.__NUXT__/i.test(target.innerHTML)) {
        target.innerHTML = ''
        break
      }
    }
  }
  const $logs = document.querySelector('#__NUXT_LOGS__')
  if ($logs) $logs.innerHTML = ''
}
</script>
<style lang="scss">
.nuxt_page {
  margin-top: $bannerHeight;
}
.hidden_scroll {
  overflow: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  /* 이외의 브라우저 */
  overscroll-behavior: none;
}
</style>
