import FetchFactory from '../factory'

class BoardModule extends FetchFactory {
  private COMMUNITY = '/community/api/v1'

  /**
   * 게시판 정보 조회
   */
  getBoardInfo(boardId: string) {
    return this.call<BoardInfo>(
      'GET',
      `${this.COMMUNITY}/board/${boardId}/`,
      undefined,
      {}
    )
  }

  /**
   * 게시판의 게시글 목록 조회
   */
  getThreadList(param: ThreadSearchParam) {
    return this.call<ThreadInfo>(
      'GET',
      `${this.COMMUNITY}/board/${param.boardId}/threadsV2`,
      undefined,
      {
        params: {
          ...param,
          pageSize: param.perPage,
          blockSize: param.perPage,
          paginationType: 'PAGING'
        }
      }
    )
  }
  /**
   * 게시판의 고정글 목록 조회
   * @param params
   * @returns
   */
  getStickyThreadList(params: { boardId: string; pageSize: number }) {
    return this.call<{ stickyThreads: [] }>(
      'GET',
      `${this.COMMUNITY}/board/${params.boardId}/stickyThreads`,
      undefined,
      {
        params
      }
    )
  }
  /**
   * 게시글 상세 조회
   */
  getThreadDetail(threadId: string) {
    return this.call<Thread>(
      'GET',
      `${this.COMMUNITY}/thread/${threadId}`,
      undefined
    )
  }
}

export default BoardModule
