import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    return new Promise((resolve) => {
      const callback = () => {
        resolve(
          savedPosition
            ? savedPosition
            : {
                top: 0
              }
        )
      }
      // 상세 페이지에서 뒤로 이동시
      if (/-id$/.test(from.name as string)) {
        nuxtApp.hooks.hookOnce('page:finish', callback)
      } else {
        callback()
      }
    })
  }
}
