import { default as contentsJfUolOL9ObMeta } from "/app/pages/contents.vue?macro=true";
import { default as download0ovpAXlwqnMeta } from "/app/pages/download.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as gateLrWVWI1DmOMeta } from "/app/pages/gate.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as introducelYo6pGqPHLMeta } from "/app/pages/introduce.vue?macro=true";
import { default as join5y5cAZNJvoMeta } from "/app/pages/join.vue?macro=true";
import { default as _91type_93O57CtlSTl5Meta } from "/app/pages/legal/[type].vue?macro=true";
import { default as legaljKqPCMARDuMeta } from "/app/pages/legal.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as _91id_93R7ovraIi4kMeta } from "/app/pages/notice/[id].vue?macro=true";
import { default as indexi5mWV2lpAmMeta } from "/app/pages/notice/index.vue?macro=true";
import { default as noticexppzncyk55Meta } from "/app/pages/notice.vue?macro=true";
import { default as termJlyDIwxnBpMeta } from "/app/pages/term.vue?macro=true";
export default [
  {
    name: contentsJfUolOL9ObMeta?.name ?? "contents",
    path: contentsJfUolOL9ObMeta?.path ?? "/contents",
    meta: contentsJfUolOL9ObMeta || {},
    alias: contentsJfUolOL9ObMeta?.alias || [],
    redirect: contentsJfUolOL9ObMeta?.redirect,
    component: () => import("/app/pages/contents.vue").then(m => m.default || m)
  },
  {
    name: download0ovpAXlwqnMeta?.name ?? "download",
    path: download0ovpAXlwqnMeta?.path ?? "/download",
    meta: download0ovpAXlwqnMeta || {},
    alias: download0ovpAXlwqnMeta?.alias || [],
    redirect: download0ovpAXlwqnMeta?.redirect,
    component: () => import("/app/pages/download.vue").then(m => m.default || m)
  },
  {
    name: faqIMkmXQpKKaMeta?.name ?? "faq",
    path: faqIMkmXQpKKaMeta?.path ?? "/faq",
    meta: faqIMkmXQpKKaMeta || {},
    alias: faqIMkmXQpKKaMeta?.alias || [],
    redirect: faqIMkmXQpKKaMeta?.redirect,
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: gateLrWVWI1DmOMeta?.name ?? "gate",
    path: gateLrWVWI1DmOMeta?.path ?? "/gate",
    meta: gateLrWVWI1DmOMeta || {},
    alias: gateLrWVWI1DmOMeta?.alias || [],
    redirect: gateLrWVWI1DmOMeta?.redirect,
    component: () => import("/app/pages/gate.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: introducelYo6pGqPHLMeta?.name ?? "introduce",
    path: introducelYo6pGqPHLMeta?.path ?? "/introduce",
    meta: introducelYo6pGqPHLMeta || {},
    alias: introducelYo6pGqPHLMeta?.alias || [],
    redirect: introducelYo6pGqPHLMeta?.redirect,
    component: () => import("/app/pages/introduce.vue").then(m => m.default || m)
  },
  {
    name: join5y5cAZNJvoMeta?.name ?? "join",
    path: join5y5cAZNJvoMeta?.path ?? "/join",
    meta: join5y5cAZNJvoMeta || {},
    alias: join5y5cAZNJvoMeta?.alias || [],
    redirect: join5y5cAZNJvoMeta?.redirect,
    component: () => import("/app/pages/join.vue").then(m => m.default || m)
  },
  {
    name: legaljKqPCMARDuMeta?.name ?? "legal",
    path: legaljKqPCMARDuMeta?.path ?? "/legal",
    meta: legaljKqPCMARDuMeta || {},
    alias: legaljKqPCMARDuMeta?.alias || [],
    redirect: legaljKqPCMARDuMeta?.redirect,
    component: () => import("/app/pages/legal.vue").then(m => m.default || m),
    children: [
  {
    name: _91type_93O57CtlSTl5Meta?.name ?? "legal-type",
    path: _91type_93O57CtlSTl5Meta?.path ?? ":type()",
    meta: _91type_93O57CtlSTl5Meta || {},
    alias: _91type_93O57CtlSTl5Meta?.alias || [],
    redirect: _91type_93O57CtlSTl5Meta?.redirect,
    component: () => import("/app/pages/legal/[type].vue").then(m => m.default || m)
  }
]
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name ?? undefined,
    path: newsWdQv0BaSvYMeta?.path ?? "/news",
    meta: newsWdQv0BaSvYMeta || {},
    alias: newsWdQv0BaSvYMeta?.alias || [],
    redirect: newsWdQv0BaSvYMeta?.redirect,
    component: () => import("/app/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93FIXkK569FdMeta?.name ?? "news-id",
    path: _91id_93FIXkK569FdMeta?.path ?? ":id()",
    meta: _91id_93FIXkK569FdMeta || {},
    alias: _91id_93FIXkK569FdMeta?.alias || [],
    redirect: _91id_93FIXkK569FdMeta?.redirect,
    component: () => import("/app/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoxV4GRc61hMeta?.name ?? "news",
    path: indexoxV4GRc61hMeta?.path ?? "",
    meta: indexoxV4GRc61hMeta || {},
    alias: indexoxV4GRc61hMeta?.alias || [],
    redirect: indexoxV4GRc61hMeta?.redirect,
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: noticexppzncyk55Meta?.name ?? undefined,
    path: noticexppzncyk55Meta?.path ?? "/notice",
    meta: noticexppzncyk55Meta || {},
    alias: noticexppzncyk55Meta?.alias || [],
    redirect: noticexppzncyk55Meta?.redirect,
    component: () => import("/app/pages/notice.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R7ovraIi4kMeta?.name ?? "notice-id",
    path: _91id_93R7ovraIi4kMeta?.path ?? ":id()",
    meta: _91id_93R7ovraIi4kMeta || {},
    alias: _91id_93R7ovraIi4kMeta?.alias || [],
    redirect: _91id_93R7ovraIi4kMeta?.redirect,
    component: () => import("/app/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi5mWV2lpAmMeta?.name ?? "notice",
    path: indexi5mWV2lpAmMeta?.path ?? "",
    meta: indexi5mWV2lpAmMeta || {},
    alias: indexi5mWV2lpAmMeta?.alias || [],
    redirect: indexi5mWV2lpAmMeta?.redirect,
    component: () => import("/app/pages/notice/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: termJlyDIwxnBpMeta?.name ?? "term",
    path: termJlyDIwxnBpMeta?.path ?? "/term",
    meta: termJlyDIwxnBpMeta || {},
    alias: termJlyDIwxnBpMeta?.alias || [],
    redirect: termJlyDIwxnBpMeta?.redirect,
    component: () => import("/app/pages/term.vue").then(m => m.default || m)
  }
]